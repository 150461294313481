'use client';

import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import styles from './page.module.scss';
import ContentWithDefault from '../contentWithDefault/page';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logException, logWarning } from '@/common/util/logging/writeLog';

export interface ErrorParams {
    params: {
        tooltipContent?: JSX.Element;
        error: Error & { digest?: string };
        reset?: () => void;
    };
}

function DefaultErrorContent(): JSX.Element {
    return <React.Fragment>Something went wrong</React.Fragment>;
}

export default function CommonError(params: ErrorParams): JSX.Element {
    const appInsights = useAppInsightsContext();

    const error = params?.params?.error;
    const reset = params?.params?.reset;

    useEffect(() => {
        if (error) {
            // Log the error to an error reporting service
            logException(appInsights, error);
        }
    }, [error, appInsights]);

    let attemptReset = undefined;
    if (typeof reset === 'function') {
        attemptReset = () => {
            // Attempt to recover by trying to re-render the segment
            logWarning(appInsights, 'Attempting to reload component');
            reset();
        };
    }

    const renderTooltip = (tooltipProps: object): JSX.Element => {
        return (
            <Popover className={styles.errorTooltip} {...tooltipProps}>
                <Popover.Header>Error</Popover.Header>
                <Popover.Body>
                    <ContentWithDefault
                        params={{ content: params?.params?.tooltipContent, defaultContent: <DefaultErrorContent /> }}
                    />
                </Popover.Body>
            </Popover>
        );
    };

    // TODO: add tooltip here

    return (
        <section className={styles.main}>
            <Row className="iconRow">
                <Col className="text-center">
                    <OverlayTrigger placement="bottom" flip={true} overlay={renderTooltip} delay={50}>
                        <FontAwesomeIcon
                            className="errorIcon"
                            icon={faBug}
                            aria-label="Error"
                            size={'2x'}
                            onClick={attemptReset}
                        />
                    </OverlayTrigger>
                </Col>
            </Row>
        </section>
    );
}
