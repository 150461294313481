'use client';

import { useAuthenticatedJsonGet } from '@/common/util/services/useAuthenticatedJsonGet';
import React, { useEffect } from 'react';
import LoadingContent from '../loading/template';
import CommonError from '@/components/error/page';

export interface AuthenticatedJsonContentParams {
    params?: {
        url?: URL;
        setData?: (data: unknown) => void;
        setLoading?: (loading: boolean) => void;
        loadingContent?: React.ReactNode;
        noDataContent?: React.ReactNode;
        noDataCriteria?: boolean;
        default?: unknown;
    };
    children?: React.ReactNode;
}

interface DataLoadingParams {
    params?: {
        message?: string;
    };
}

function DataLoadingError(params: DataLoadingParams): JSX.Element {
    const message = params?.params?.message || 'Error loading data, click to retry';
    return <React.Fragment>{message}</React.Fragment>;
}

export default function AuthenticatedJsonContent(params: AuthenticatedJsonContentParams): JSX.Element {
    const url = params?.params?.url;
    const setData = params?.params?.setData;
    const setLoading = params?.params?.setLoading;
    const defaultValue = params?.params?.default;

    const [results, loading, error, mutate] = useAuthenticatedJsonGet(url);

    useEffect(() => {
        // Skip if we don't have an external function to update
        if (!setLoading) {
            return;
        }

        setLoading(loading);
    }, [loading, setLoading]);

    useEffect(() => {
        // Skip if we don't have an external function to update
        if (!setData) {
            return;
        }

        // Don't update data until we're done loading
        if (loading) {
            return;
        }

        let newResults = defaultValue;
        if (results !== undefined) {
            newResults = results;
        }

        setData(newResults);
    }, [results, loading, setData, defaultValue]);

    if (error) {
        return <CommonError params={{ error: error, reset: mutate, tooltipContent: <DataLoadingError /> }} />;
    }

    return (
        <LoadingContent
            params={{
                loading: loading,
                data: results,
                noDataContent: params?.params?.noDataContent,
                noDataCriteria: params?.params?.noDataCriteria,
                loadingContent: params?.params?.loadingContent,
            }}
        >
            {params?.children}
        </LoadingContent>
    );
}
