'use client';

import { Spinner, useNeedsLoadingIcon } from '@/components/Spinner/Spinner';
import { useHasData } from './useHasData';
import ContentWithDefault from '@/components/contentWithDefault/page';
import React from 'react';

export interface LoadingContentParams {
    params?: {
        loading?: boolean | boolean[];
        data?: unknown;
        loadingContent?: React.ReactNode;
        noDataContent?: React.ReactNode;
        noDataCriteria?: boolean;
    };
    children?: React.ReactNode;
}

/**
 * TODO: Look into switching to Suspense at some point
 *
 * (useSWR currently doesn't recomment it for fetching data)
 * https://swr.vercel.app/docs/suspense
 *
 * @param params
 * @returns
 */
export default function LoadingContent(params: LoadingContentParams): JSX.Element {
    // Use hooks to reduce the amount of change
    const loading = useNeedsLoadingIcon(params?.params?.loading);
    const hasData = useHasData(params?.params?.data, params?.params?.noDataCriteria);

    if (loading) {
        return <ContentWithDefault params={{ content: params?.params?.loadingContent, defaultContent: <Spinner /> }} />;
    }

    if (!hasData) {
        return <ContentWithDefault params={{ content: params?.params?.noDataContent }} />;
    }

    return <React.Fragment>{params?.children}</React.Fragment>;
}
