'use client';
import { useEffect, useState } from 'react';

export function useHasData(data?: unknown, noDataCriteria?: boolean) {
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        let noData = data !== undefined;

        // If we have criteria input, use that
        if (noDataCriteria !== undefined) {
            noData = noDataCriteria;
        }

        setHasData(noData);
    }, [data, noDataCriteria]);

    return hasData;
}
