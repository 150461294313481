import fetchBuilder, { RequestInitRetryParams } from 'fetch-retry';

export const fetchRetryConfig: RequestInitRetryParams<typeof fetch> = {
    retries: 3,
    retryDelay: function (attempt) {
        // Exponential delay for retries, starting at 100ms
        return Math.pow(2, attempt) * 100;
    },
    retryOn: [429, 500, 503],
};

/**
 * Wrap native fetch with the ability to automatically retry on network errors
 */
export const fetchWithRetry = fetchBuilder(fetch, fetchRetryConfig);
