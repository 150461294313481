import memoize from 'fast-memoize';
import { normalizeValue } from './strings/normalizeValue';

/**
 * JSON stringify for 2 values are equal
 *
 * @param valueA
 * @param valueB
 */
function _equalJson<T>(valueA: T, valueB: T): boolean {
    return JSON.stringify(valueA) === JSON.stringify(valueB);
}
export const equalJson = memoize(_equalJson);

export function copyJson<T>(value: T): T {
    if (!value) {
        return undefined;
    }
    return JSON.parse(JSON.stringify(value));
}

export function formatPhoneNumber(value: string): string {
    const validated = normalizeValue(value);
    if (!validated) {
        return undefined;
    }

    // TODO: handle more than just 10 digit numbers
    if (validated.length > 10) {
        return validated;
    }

    const areaCode = validated.substring(0, 3);
    const prefix = validated.substring(3, 6);
    const suffix = validated.substring(6);

    return `(${areaCode}) ${prefix}-${suffix}`;
}

export function getObject(data?: unknown): unknown {
    return data || {};
}
