'use client';

import { ReactNode } from 'react';

export interface ContentWithDefaultParams {
    params: {
        content?: ReactNode;
        defaultContent?: ReactNode;
    };
}

/**
 * Display something if defined, or display a fallback
 *
 * @param params
 * @returns
 */
export default function ContentWithDefault(params: ContentWithDefaultParams): JSX.Element {
    let content = params?.params?.content;

    // Return default content
    if (content === undefined) {
        content = params?.params?.defaultContent;
    }

    // Make sure we return null if nothing is defined
    if (content === undefined) {
        content = null;
    }

    return <>{content}</>;
}
