import { useJsonEqualValue } from '@/hooks/useJsonEqualState';

/**
 * Hook for keeping track of a URL
 *
 * Ensure that the URL object only updates when the resulting string changes
 *
 * @param url
 * @returns
 */
export function useURL(url: URL): URL {
    const value = useJsonEqualValue<URL>(url);
    return value;
}
